<template>
  <div class="error">
    <!-- <img src="~@/assets/404a.png" alt=""> -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>
<style scoped lang="less">
.error {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url(~@/assets/404.png) no-repeat center;
  background-size: contain;
  //   img {
  //       width: 100%;
  //       height: 100%;
  //   }
}
</style>
